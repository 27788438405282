.Page {
  text-align: left;
  background-color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  font-size: calc(10px + 2vmin);
  color: white;
  text-shadow: 2px 2px #000;
}

h1 {
  width: 44vw;
}

section {
  font-size: calc(8px + 2vmin);
  width: 40vw;
  margin: 20px;
}
.social-media-icons > *{
  margin-right: 10px;
}

.App-link {
  color: #61dafb;
}